import { createStore } from 'vuex'

export default createStore({
  state: {
    projets: [
      {id: 0, coolname: 'api-weather', beautifulname: 'API Méteo', smiley: 'https://www.remihr.fr/smileys/weather.png', illus: 'https://www.remihr.fr/illus/weather.png', desc: 'J\'ai développé ce site. Il utilise l\'API WeatherAPI. Il a été conçu dans le cadre de mes études avec pour contrainte d\'utiliser cette API et de respecter une maquette fournit.', link: 'https://www.remihr.fr/weather', tools: '- Html - Css - Php - JavaScript - Api REST -', colorback: '#DE781A', colortext: '#fff'},
      {id: 1, coolname: 'showcase-carpenter', beautifulname: 'Site vitrine charpentier', smiley: 'https://www.remihr.fr/smileys/donsimoni.png', illus: 'https://www.remihr.fr/illus/donsimoni.png', desc: 'Ce site, que j\'ai développé seul, a été conçu pour un professionnel : Franck Donsimoni dans le cadre de mes études. L\'une des contraintes obligatoires dans la conception de ce site était d\'utiliser Wordpress ainsi qu\'ACF un plugin Wordpress pour aider à l\'administration du site.', link: 'https://www.franckdonsimoni.fr', tools: '- Html - Css - Php - Javascript - Wordpress -', colorback: '#DDBE1B', colortext: '#fff'},
      {id: 2, coolname: 'api-cocktail', beautifulname: 'API Cocktail', smiley: 'https://www.remihr.fr/smileys/cocktail.png', illus: 'https://www.remihr.fr/illus/cocktail.png', desc: 'J\'ai conçu et intégré ce site. Il utilise l\'API Cocktail. Il a été conçu dans le cadre de mes études avec les contraintes d\'utiliser SCSS, d\'avoir une structure JavaScript sous forme de classes et d\'utiliser WebPack.', link: 'https://www.remihr.fr/cocktail', tools: '- Html - Scss - Php - JavaScript - Api REST - WebPack -', colorback: '#EA6DB4', colortext: '#fff'},
      {id: 3, coolname: 'showcase-agency', beautifulname: 'Site vitrine agence web', smiley: 'https://www.remihr.fr/smileys/cabane.png', illus: 'https://www.remihr.fr/illus/cabane.png', desc: 'Ce site a été conçu en collaboration avec un autre élève de ma classe. Notre objectif était de représenter toutes les valeurs de notre classe transformée en agence web le temps d\'un semestre. Aucune contrainte pour ce projet.', link: 'https://www.remihr.fr/cabane', tools: '- Html - Css - Php - JavaScript -', colorback: '#DBF8FF', colortext: '#01003f'},
      {id: 4, coolname: 'this-website', beautifulname: 'Ce site', smiley: 'https://www.remihr.fr/smileys/remihr.png', illus: 'https://www.remihr.fr/illus/remihr.png', desc: 'Ce site a de nouveau été conçu dans le cadre de mes cours. Il me sert de site portfolio. J\'ai choisi d\'utiliser Vue Js pour ce projet afin de m\'améliorer dans ce langage.', link: 'https://www.remihr.fr', tools: '- Html - Scss - JavaScript - Vue JS - Vue CLI - Vue X - Vue Rooter -', colorback: '#DFD2C3', colortext: '#171717'},
      {id: 5, coolname: 'showcase-electrician', beautifulname: 'Site vitrine électricien', smiley: 'https://www.remihr.fr/smileys/electrician.png', illus: 'https://www.remihr.fr/illus/electrician.png', desc: 'J\'ai réalisé ce site lors de mon stage de fin de diplôme. Je l\'ai conçu et développé moi-même.', link:'https://www.pierre-elec.com', tools: '- HTML - SCSS - Sage Starter Theme - PHP/Blade - NodeJS - ', colorback: '#EE2024', colortext: '#fff'}
    ]
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
